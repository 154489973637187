

// SOURCE_SANS
@font-face {
  font-family: Source_sans_light;
  font-weight: 300;
  src: url("../fonts/Source_sans/SourceSansPro-Light.ttf");
}
@font-face {
  font-family: Source_sans_regular;
  font-weight: 400;
  src: url("../fonts/Source_sans/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: Source_sans_semiBold;
  font-weight: 600;
  src: url("../fonts/Source_sans/SourceSansPro-SemiBold.ttf");
}
@font-face {
  font-family: Source_sans_bold;
  font-weight: 700;
  src: url("../fonts/Source_sans/SourceSansPro-Bold.ttf");
}
@font-face {
  font-family: Source_sans_black;
  font-weight: 900;
  src: url("../fonts/Source_sans/SourceSansPro-Black.ttf");
}
//

// MONTSERRAT
@font-face {
  font-family: Montserrat_light;
  font-weight: 300;
  src: url("../fonts/Montserrat/Montserrat-Light.ttf");
}
@font-face {
  font-family: Montserrat_regular;
  font-weight: 400;
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat_semiBold;
  font-weight: 600;
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: Montserrat_bold;
  font-weight: 700;
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
}
@font-face {
  font-family: Montserrat_black;
  font-weight: 900;
  src: url("../fonts/Montserrat/Montserrat-Black.ttf");
}
//
html body {
  @layer @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @layer @apply font-display;
}

:root {
  --font-display: 'Montserrat';
  --font-body: 'Montserrat';
}

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}

.theme-astronomy {
  --font-display: BeVietnam;
  --font-body: BeVietnam;
}
