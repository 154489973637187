// RESPONSIVE Dewey Tablette ==========================================

@media only screen and (max-width: 980px) {
  .nc-Footer{
    padding: 2rem;
  }

  .container-vosprojets .container-content-vosprojets{
    padding: 50px;
    h2{
      font-size: 32px;
    }
    p{
      font-size: 15px;
      font-size: 15px;
    }
  }
  .container-groupeHeader{
    margin-bottom: 0px;
  }
  .container-v2{
    padding: 20px 100px;
  }
  /* Général Footer */
  .nc-Footer .container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    .grid {
      display: block;
      text-align: center;
      margin: 0 auto;
    }
  }
  .container-calcu {
    p {
      width: 90%;
    }
  }
  /* Header Home */
  .container-headerhome{
    width: 100%;
    height: 60vh;
    background-position-y: 0;
    .container-content-headerhome{
      width: 80%;
      height: auto;
      transform: translateY(20%);
      padding: 5%;
      h2{
        font-size: 34px;
      }
      .txt-header{
        font-size: 16px;
        margin: 20px auto;
      }
      .container-cards-header{
        display: none;
      }
      .container-formheader{
        width: 100%;
        background-color: transparent;
        form{
          display: flex;
          flex-direction: column;
          height: 100px;
          select{
            width: 100%;
          }
          button{
            width: 100%;
            margin-top: 20px;
          }
        }

      }
    }
  }
  .title-content{
    margin: 50px 0 20px 0;
  }
  .container-accompagnement .container-image{
    width: 100%;
  }
  .container-all-blog .container-blogs{
    grid-template-columns: repeat(3, 1fr);
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
  }
  .container-newsletter p{
    width: 60%;
  }
  .container-selector {
    width: 80% !important;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .container-selector a{
    margin: 20px 0;
  }
  .container-cards{
    grid-template-columns: repeat(2, 1fr);
  }
  /* Home Programme */
  .container-programmes-all{
    h1{
      line-height: 1.2;
    }
  }
  .container-programes{
    margin: 100px 0;
    display: block;
    justify-content: space-around;
  }
  .container-txt-programes{
    width: 100%;
  }
  .container-img-programmes{
    width: 100%;
    img{
      margin-top: 50px;
    }
  }
  /* Home Groupe */
  .container-groupe{
    display: block;
    padding: 10%;
  }
  .container-img-groupe{
    width: 100%;
  }
  .container-txt-groupe{
    width: 100%;
  }
  /* Home Expertises */
  .container-expertises{
    display: block;
    width: 100%;
    height: auto;
    .container-expertise{
      display: block;
      .expertise-single{
        width: 90%;
      }
      .expertise-single:nth-child(4n){
        margin-left: 0px;
      }

    }
  }
  /* Home Accompagnement */
  .container-accompagnement{
    width: 100%;
    display: block;
    .container-txt-accompagnement{
      width: 100%;
    }
    .container-image{
      position: relative;
      img{
        width: 100%;
        margin-left: inherit;
        margin-top: 50px;
      }
    }
  }
  /* Page Groupe */
  .container-nos-valeur{
    width: 100%;
    display: block;
    .container-txt-nos-valeur{
      width: 100%;
    }
  }
  .container-champs-calcu{
    flex-direction: column;
    width: 90%;
    .inputs-calcu{
      height: 250px;
      width: 100%;
      margin: 0;
    }
    .container-response{
      border-top-right-radius: unset;
      border-bottom-left-radius: 20px;
      height: 150px;
      p{
        width: 60%;
      }
      .resultBlock{
        width: 50%;
        display: inherit !important;
      }
    }

  }
  .container-autres-articles .container-aa-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 30px;
  }

}
@media only screen and (min-width: 800px) {
  .container-autres-articles .container-aa-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 30px;
  }
}

@media only screen and (min-width: 1200px)
and (max-width: 1500px) {
  .container-autres-articles .container-aa-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 30px;
  }
}
@media only screen and (min-width: 1501px)   {
  .container-autres-articles .container-aa-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 30px;
  }
}

// RESPONSIVE Dewey Mobile ==========================================
@media only screen and (max-width: 500px) {
  .programmesh-display{
    grid-template-columns: repeat(1, 1fr) !important;
    grid-row-gap: 30px;
    .programmesh-container{
      img{
        height: 200px;
      }
    }
  }
  .list-ville{
    p{
      width: 100% !important;
    }
  }
  .container-programes{
    margin: 50px 0;
    .container-img-programmes{
     img{
       width: 100%;
     }
    }
  }
  .container-calcu{
    margin: 30px 0;
    padding: 100px 16px;
  }
  .container-prog{
    padding: 24px;
  }
  .programmes{
    ul{
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .container-map-programmes{
    .list-btn{
      width: 100%;
      ul{
        grid-template-columns: repeat(1, 1fr) !important;
      }
    }
  }
  .container-blogheader{
    margin: 30px 0;
  }
  .search-input{
    width: 100%;
  }
  .nc-MainNav1{
    padding: 0 2rem;
  }
  .container-social-dark{
    display: flex;
    align-items: center;
  }
  .container-cara-ptsforts-single-prog{
    flex-direction: column;
    height: 100%;
    .container-cara{
      width: 100% !important;
    }
    .container-ptsforts{
      width: 100% !important;
      margin-top: 32px;
    }
  }
  .container-page-single-prog {
    .container-biens-dispo {
      p{
        width: 100%;
      }
    }
  }
  .container-content-headerhomedeux {
    width: 70%;
    height: 400px;
    margin: 0 auto;
    transform: translateY(3%);
    padding: 30px 20px 0;

    h1 {
      text-align: center;
      font-family: Montserrat_semiBold, sans-serif;
      font-weight: 600;
      color: white;
      font-size: 28px;
      line-height: 40px;
    }

    .txt-header {
    display: none;
    }

  }
  /* Logo */

  .ttnc-logo{
    text-align: left;
    display: inherit !important;
    svg{
      width: 130px ;
    }
  }
  .container-headerhome{
    .container-content-headerhome{
      h1{
        font-size: 30px;
        margin-bottom: 16px;
      }
    }
  }
  .container-champs-calcu{
    flex-direction: column;
    width: 90%;
    .inputs-calcu{
      height: 250px;
      width: 100%;
      margin: 0;
    }
    .container-response{
      border-top-right-radius: unset;
      border-bottom-left-radius: 20px;
      height: 150px;
      p{
        width: 60%;
      }
      .resultBlock{
        display: inherit !important;
      }
    }
    .container-paragraphe-1{
      width: 90%;
      margin: 0 auto;
    }
  }

  .container-calcu{
    h2{
      line-height: 1.2;
      font-size: 28px;
    }
  }

  /* Home */
  .container-headerhome {
    height: 60vh;
    .container-content-headerhome {
      height: auto;
      transform: translateY(40%);
      h2{
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 32px;
      }
      .txt-header{
        display: none;
        font-size: 16px;
      }
    }
  }
  .title-content{
    font-size: 28px;
    margin: 50px 0 20px 0;
    text-align: center;
  }
  .txt-content{
    font-size: 16px;
    text-align: center;
  }
  .container-buttons{
    margin: 50px 0;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .container-groupe{
    display: flex;
    flex-direction: column-reverse;
    .container-img-groupe{
      img{
        width: 100%;
        margin-top: 50px;
        height: 300px;
        object-fit: cover;
        border-radius: 20px;
      }
    }
    .title-content{
      text-align: left;
    }
    .txt-content{
      text-align: left;
      margin: 20px 0;
    }
    .container-button{
      margin: 30px 0;
    }
  }
  .container-expertises{
    .container-expertise{
      .expertise-single{
        justify-content: space-evenly;
        flex-direction: column;
        align-items: baseline;
        .expertises-title{
          font-size: 28px;
        }
      }
      .expertise-single:nth-child(3n), .expertise-single:nth-child(5n){
        margin-left: 80px;
      }
    }
  }
  .container-focus{
    margin: 0;
  }
  .container-v2{
    margin: 0;
  }
  .container-buttons button.btn-1{
    width: 100%;
    margin: 5px;
  }
  .container-buttons button.btn-2 {
    width: 60%;}
  .expertise-single:first-child {
    margin-left: 0;
  }
  .container-focus {
    padding: 10%;
  }
  .container-v2{
    padding: 10%;
  }
  .container-accompagnement .container-txt-accompagnement h2{
    font-size: 24px;
    margin: 50px 0 0;
  }
  .container-accompagnement{
    .container-image{
      width: 100%;
    }
  }
  .container-accompagnement .container-image button {
    padding: 8px 20px;
  }
  .container-all-blog{
    .container-blogs{
      grid-template-columns: repeat(1, 1fr);
      overflow-x: scroll;
      overflow-y: hidden;
      grid-column-gap: 50px;
      width: 100%;
      .container-blog{
        display: inline-block;
      }
    }
  }
  .container-partenaire h2 {
    line-height: 1.2;
    font-size: 28px;
  }
  .container-partenaire p {
    width: 100%;
  }
  .container-newsletter form {
    padding: 30px 30px;
    width: 100%;
  }
  .container-newsletter h2{
    font-size: 28px;
  }
  .container-newsletter p{
    width: 80%;
  }
  .col-span-2{
    grid-column: inherit !important;
  }
  .nc-Footer{

    .container{
      .text-sm{
        height: 200px;
      }
    }

  }
  /* PAGE PROGRAMMES */
  .container-programmes-all{
    h1{
      font-size: 28px;
    }
    .container-map-programmes{
      flex-direction: column-reverse;
    }
    ul{
      width: 100% !important;
      .container-programme{
        flex-direction: column;
      }
      .img-programme{
        width: 100%;
        height: 200px;
      }
      .container-content-programme{
        margin-left: 16px;
      }
    }
    .container-only-map{
      height: 400px;
      width: 100% !important;
    }
  }
  .container-vosprojets{
    .container-content-vosprojets{
      width: 100%;
      h2{
        line-height: 1.2;
        font-size: 28px;
      }
      p{
        font-size: 16px;
        margin: 30px;
      }
    }
  }
  /* Page Le Groupe */
  .container-groupeHeader{
    p{
      width: 100%;
    }
  }
  .container-txt-accompagnement{
    .container-buttons{
      display: block;
      height: auto;
      button.btn-2{
        padding: 0px;
        a{
          font-size: 12px;
        }
      }
    }
  }
  .container-accompagnement{
    .container-image{
      height: auto;
    }
  }

  .naissance-kotimo {
    padding: 0 24px;
    margin: 30px 0 30px 0;
    h2{
      font-size: 28px;
      line-height: 1.2;
    }
  }
  .container-equipe{
    .nc-Section-Heading{
      .max-w-2xl{
        h2{
          text-align: left;
          font-size: 28px;
        }
      }
    }
  }
  .container-nos-valeur {
    .container-txt-nos-valeur{
      h2{
        font-size: 28px;

      }
      .container-buttons{
        height: auto;
        display: block;
        button.btn-2{
          padding: 0 32px;
          width: 80%;
          a{
            font-size: 12px;
          }
        }
      }
    }
  }
  .container-form-contact-component{
    width: 90%;
  }
  .container-equipe .nc-Section-Heading .max-w-2xl{
    padding: 0;
  }
  .container-all-bg .container-comingsoon{
    width: 100%;
    padding: 30px;
  }
  .container-all-bg .container-comingsoon .container-coming{
    height: 145px;
  }
  .container-all-bg {
    .container-comingsoon{
      .container-coming{
        .button-comingsoon{
          padding: 4px 16px;
          a{
            font-weight: 600;
            letter-spacing: 4px;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  /* PAGE BLOG */
  .container-selector{
    flex-direction: column;
  }
  .container-cards {
    grid-template-columns: repeat(1,1fr);
    width: 100%;
  }
  /* PAGE BLOG SINGLE */
  .container-single-blog{
    .header-blog-single{
      margin: 50px 0 100px;
    }
  }
  .MuiBreadcrumbs-ol{
    justify-content: center;
  }
  .titleretour{
    a{
      width: 10%;
    }
  }
  .container-single-blog .header-blog-single h1 {
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    width: 80%;
  }
  .container-autres-articles {
    .title-autres-articles{
      font-size: 28px;
    }
    .container-cards{
      overflow-x: scroll;
      overflow-y: hidden;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 50px;
      a{
        display: inline-block;
      }
    }
  }
  .container-single-blog .container-bloc-1-single{
    flex-direction: column;
  }
  .container-single-blog .container-bloc-1-single .container-txt-1-single {
    width: 90%;
    margin: 0 auto;
    h2{
      line-height: 1.2;
    }
  }
  .container-single-blog .container-bloc-1-single .container-image-1-single {
    width: 90%;
    margin: 20px auto;
  }
  .container-single-blog .container-bloc-2-single{
    flex-direction: column;
  }
  .container-bloc-2-single .container-txt-2-single {
    width: 90%;
    margin: 0 auto;
    h2{
      line-height: 1.2;
    }
  }
  .container-bloc-2-single .container-image-2-single {
    width: 90%;
    margin: 20px auto;
  }
  /* PAGE CONTACT */
  .container-form-partenaire{
    h1{
      font-size: 32px;
    }
  }
  .switch-darkmode{
    width: 36px !important;
    height: 36px !important;
    .w-7{
      width: 1.25rem;
    }
  }
  .nc-Footer{
    .grid{

      .col-span-2{
        p{
          text-align: left;
        }
      }
    }
  }

  .border-bottom-mobile-footer{
    margin-top: 30px;
    width: 150px;
    border-bottom: 1px solid black;
  }
  .nc-SectionSliderNewCategories .nc-Section-Heading .max-w-2xl h2{
    font-size: 28px;
  }
  .container-all-blog .title-blog{
    font-size: 28px;
  }
  .container-form-groupe h1{
    font-size: 28px;
  }
}
@media only screen and (max-width: 1000px){
  .container-content-headerhomedeux{
    padding: 50px 20px !important;
    h1{
      font-size: 32px;
    }
  }
  .container-map-programmes {
    .list-btn {
      width: 100%;
      .container-list-gradient {
        ul {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }

  }
  .list-ville{
    p{
      width: 50%;
    }
  }
  .programmesh-display {
    grid-template-columns: repeat(2, 1fr);
    .programmesh-container {
      img{
        height: 150px;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .container-content-headerhomedeux{
    height: auto;
    padding: 100px 20px;
    transform: translateY(16%);
  }
  .container-autres-articles{
    .container-cards{
      overflow-x: scroll;
      overflow-y: hidden;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 50px;
    }
  }
  .container-headerhome{
    .container-content-headerhome {
      padding: 50px 20px 50px;
      .txt-header{
        font-size: 18px;
        width: 90%;
      }
      .container-cards-header {
        display: none;
        width: 100%;
      }
      .container-formheader {
        width: 100%;
        margin: 70px auto;
      }
    }
  }
  .container-groupeHeader{
    padding: 110px 5% !important;
  }
  .item-footer{
    font-size: 11px;
  }
  .container-programmes-all{
    h1{
      font-size: 32px;
    }
  }
  .container-map-programmes{
    .container-only-map{
      width: 50%;
    }
    ul{
      width: 50%;
    }
  }

}

@media only screen and (max-width:850px){
  .container-slider-fr{
      grid-template-columns: repeat(1, 1fr) !important;
  }
  .container-cards{
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .container-aa-cards{
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .container-map-programmes{
    ul{
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
@media only screen and (max-width:1200px){
  .container-slider-fr{
      grid-template-columns: repeat(2, 1fr);
  }
  .container-cards{
    grid-template-columns: repeat(2, 1fr);
  }
  .container-aa-cards{
    grid-template-columns: repeat(2, 1fr);
  }
  .container-map-programmes{
    display:flex;
    flex-direction: column-reverse;

    ul{
      width: 100%;
      padding-right: inherit;
      padding-top: 50px;
      padding-top: 50px;
    }
    .container-only-map{
      width: 100%;
    }
  }
}
@media (min-width: 1200px) and (max-width:1350px){
  .container-map-programmes{
    ul{
      grid-template-columns: repeat(1, 1fr);
    }
  }
}