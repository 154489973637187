.root {
  overflow: hidden;
}
/*SCROLL ON FOCUS REGIONAUX */
.swiper{
  overflow: scroll;
  overflow-y: unset;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.z-max {
  z-index: 9999;
}

.nc-will-change-transform {
  will-change: transform;
}
.nc-will-change-top {
  will-change: top;
}


.nc-SectionClientSay .glide__bullet--active {
  @layer @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @layer @apply bg-white w-2 h-2;
}


.nc-card-title {
  @layer @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}


.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
&:hover {
span {
  color: inherit;
}
}
}

//
.nc-box-has-hover {
  @apply bg-white rounded-3xl border border-neutral-200 border-opacity-70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:shadow-2xl;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

// HERO
   .nc-hero-field-padding {
     @apply py-3 px-4 md:py-4 md:px-7 xl:py-6 xl:px-10;
   }

.nc-flex-1 {
  flex: 1 0 0%;
}

.nc-flex-1\.5 {
  flex: 1.5 0 0%;
}
.nc-flex-1\.5-auto {
  flex: 1.5 0 auto;
}

.nc-flex-2 {
  flex: 2 0 0%;
}

.nc-flex-2-auto {
  flex: 2 0 auto;
}

.nc-flex-2\.5 {
  flex: 2.5 0 0%;
}

@screen lg {
  .lg\:nc-flex-1\.5 {
    flex: 1.5 0 0%;
  }
  .lg\:nc-flex-1\.5-auto {
    flex: 1.5 0 auto;
  }

  .lg\:nc-flex-2 {
    flex: 2 0 0%;
  }

  .lg\:nc-flex-2-auto {
    flex: 2 0 auto;
  }

  .lg\:nc-flex-2\.5 {
    flex: 2.5 0 0%;
  }
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
.nc-icon-field-2 {
  @apply w-5 h-5 lg:w-8 lg:h-8;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

//
.nc-FiveStartIconForRate {
svg:hover ~ svg {
  @apply text-neutral-300;
}
}

// LISTING PAGE DETAIL
   .listingSection__wrap {
     @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8;
   }

//
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type="button"] {
  -webkit-appearance: none !important;
}

//
.rc-slider-track {
  @layer @apply bg-primary-400;
}
.rc-slider-handle {
  @layer @apply border-primary-300;
}

//
.nc-PropertyCardH {
.nc-BtnLikeIcon--liked {
  @apply text-red-500 dark:text-red-500;
}
}

//
.ncSectionLogos {
img {
  @apply w-full;
  max-width: 200px;
}
}
.navigation-little{
  max-width: inherit !important;
}
//
.modalPhotos-single-gallery {
.glide__bullet--active {
  @apply block;
}
}

/* GLOBAL */
.button-v{
  font-family: 'Montserrat_semiBold';
  color: white;
  background-color: #3b0f70;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%);
}
.button-v:hover{
  background-color: #A08155;
  transition: .5s;
}
.button-g{
  background-color: #A08155;
  padding: 8px 16px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  font-family: 'Montserrat_semiBold';
  letter-spacing: 2px;
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%);
}
.button-g-out{
  background-color: white;
  border: 1px solid white;
  padding: 8px 16px;
  border-radius: 20px;
  color: #A08155;
  font-size: 14px;
  font-family: 'Montserrat_semiBold';
  LETTER-SPACING: 2px;
 box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%);

}
.button-g-out:hover{
  background-color: transparent;
  color: white;
  border: 1px solid white;
  transition: .5s;
}
.button-g:hover{
  transition: .5s;
  background-color: #D5C5AF;
}
.button-v-out{
  border: 1px solid #3b0f70;
  padding: 8px 16px;
  border-radius: 20px;
  font-family: Montserrat_semiBold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #3B0F70;
  font-size: 14px;
  box-shadow:0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%);
}
.button-v-out:hover{
  background-color: #3b0f70;
  color: white;
  transition: .5s;
}
.container-v2{
  padding: 100px;
  margin-top: 0 !important;
}
.container-prog{
  padding: 24px 100px;
  margin-top: 0;
}
.container-v3{
  padding: 0 100px;
  margin-top: 0;
}
.nc-MainNav1.notOnTop{
  background-color: #F5F5F5 !important;
  border-color: inherit !important;
}
.menuIsNew_lv1 {
> a::before {
  content: "New!";
  position: absolute;
  top: -2px;
  right: 0;
  display: inline-block;
  padding: 0 5px;
  line-height: 15px;
  border-radius: 4px;
  background-color: #f35656;
  color: #fff;
  font-size: 10px;
  font-size: 10px;
  font-weight: normal;
}
}
.menuIsNew {
> a::after {
  content: "New!";
  display: inline-block;
  padding: 0 5px;
  line-height: 15px;
  border-radius: 4px;
  background-color: #f35656;
  color: #fff;
  font-size: 10px;
  margin-left: 6px;
}
}
.nc-Navigation{
li{
a{
  font-family: Montserrat_semibold;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}
a:hover{
  color: #A08155;
  transition: .2s;
}
}
}
.nc-MainNav1{
  background-color: #F5F5F5;
  padding: 0 6rem;
}
.nc-Footer{
  background-color: #F5F5F5;
}
.margin-bottom{
  margin-bottom: 100px !important;
}

/* KOTIMO */
.nc-MainNav1{
.nc-Button{
  font-family: Montserrat_semiBold;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 4px 32px;
}
.nc-Button:hover{
  background-color: #4E2B6D;
  transition: .2s;
}
}
.nc-Navigation{
  font-family: Montserrat_regular;
  font-family: Montserrat_regular;
}

.container-programes{
  margin: 50px 0;
  display: flex;
  justify-content: space-around;
h2{
  font-family: Montserrat_semiBold;
}
button{
a{
  font-family: Montserrat_semiBold;
}
}
}
.container-txt-programes{
  width: 45%;
}
.txt-programmes-all{
h1{
  font-family: Montserrat_SemiBold !important;
}
}
.container-content-programme{
h2{
  font-family: Montserrat_SemiBold !important;
}
}
.container-img-programmes{
  width: 45%;
img{
  border-radius: 20px;
  width: 88%;
  height: 95%;
  margin: auto;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.30) 0px 8px 16px -8px;
}
}
.container-buttons{
  margin: 20px 0;
}
.container-buttons button.btn-1 {
  background-color: #A08155;
  color: white;
  padding: 4px 16px;
  border-radius: 20px;
  margin: 0 20px 0 0;
  border: 1px solid #A08155;
}
.container-buttons button.btn-1:hover{
  background-color: #988363;
  border: 1px solid #988363;
  padding: 4px 16px;
  border-radius: 20px;
  margin: 0 20px 0 0;
  transition: .2s;
}
.container-buttons button a{
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
}
.container-buttons button.btn-2{
  color: white;
  background-color:#3b0f70;
  padding: 4px 16px;
  border-radius: 20px;
  border: 1px solid #3b0f70;
  font-family: Montserrat_semiBold;
}
.container-buttons button.btn-2:hover{

  background-color: #4E2B6D;
  border: 1px solid #4E2B6D;
  transition: .2s;
  padding: 4px 16px;
  border-radius: 20px;
}
.title-content{
  font-family: Montserrat_semiBold;
  font-size: 40px;
  line-height: 45px;
  font-weight: 700;
  margin: 0 0 30px 0;
}
.txt-content{
  font-size: 16px;
  font-family: Source_sans_regular;
}

.container-groupe{
  display: flex;
  justify-content: space-around;
  background-color: #A08155;
}
.container-img-groupe{
  width: 45%;
img{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.30) 0px 8px 16px -8px;
}
}
.container-txt-groupe{
  width: 45%;
  color: white;
  margin: auto 0;
h2{
  font-family: Montserrat_semiBold;
}
button{
  width: 168px;
a{
  font-family: Montserrat_semiBold;
}
}
}
.container-button{
  margin: 20px 0;
  background-color: inherit;
  border: 1px solid white;
  padding: 4px 16px;
  border-radius: 20px;
  display: inline-flex;
  width: 168px;
}
.container-button:hover{
  background-color: #988363;
  border: 1px solid white;
  transition: .2s;
}
.container-button button a{
  letter-spacing: 4.16px;
  font-weight: 600;
  font-size: 16px;
}
.container-focus{
  background-color: #F8F8F8;
  padding: 100px 0 100px 100px;
}
.container-slider-fr .swiper-wrapper{
  margin-top: 48px !important;
  height: 330px;
}
.container-slider-fr-empty .swiper-wrapper{
  height: 80px;
}
.container-slider-fr-empty .swiper{
  overflow: hidden;
}
.blogg.container-cards-empty{
  width: 100%;
  p{
    text-align: center;
    margin-bottom: 30px;
  }
}

.swiper-pagination-bullet-active{
  background: #3b0f70;
}
.swiper-backface-hidden .swiper-slide{
  width: 270px !important;
  height: 200px;
  margin-right: 50px;
  h3{
    font-family: Montserrat_regular, sans-serif;
    margin: 24px 0 12px 0;
    font-size: 1.125rem;
  }
  img{
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
  }
}


/* PAGE HOME V2 */

.container-reassurance{
  margin: 0 !important;
  background-color: #F5F5F5;
  padding-top: 6rem;
  padding-bottom: 6rem;
  p{
    font-family: Montserrat_semiBold;
  }
}
.container-3parts{
  h2{
    font-family: Montserrat_semiBold;
  }
  h3{
    font-family: Montserrat_semiBold;
  }
}


/* Composant Nos Expertises */


.container-expertises{
  display: flex;
  width: 100%;
  height: auto;

.container-expertise{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

.expertise-single {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;

.counter-expert{
  font-family: 'Source_Sans_light';
}

p{
  font-family: 'Source_sans_regular';
  width: 80%;
}
.expertises-title{
  line-height: 1.2;
  font-size: 40px;
  font-weight: 600;
  font-family: Montserrat_semiBold;
}
}
.expertise-single:nth-child(4n){
  margin-left: 400px;
}

}
}
.container-content-headerhomedeux {
  width: 70%;
  height: 462px;
  margin: 0 auto;
  transform: translateY(30%);
  padding: 100px 20px 0;
  background-image: url("../images/monogramme-kotimo.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  h1{
  text-align: center;
  font-family: Montserrat_semiBold, sans-serif;
  font-weight:600;
  color: white;
  font-size: 40px;
  line-height: 45px;
}
  .txt-header{
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin: 20px auto 50px;
    font-family: 'Source_sans_regular';
  }
  .container-button-header{
    text-align: center;
  }
}

.container-headerhome {
  width: 100%;
  height: 100vh;
  background-image: url('../images/bg-headerhome.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -200px;

  .container-content-headerhome {
    width: 70%;
    height: 462px;
    margin: 0 auto;
    transform: translateY(30%);
    padding: 100px 20px 0;
    background-image: url("../images/monogramme-kotimo.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;

    .container-button-header {
      text-align: center;
    }

    h1 {
      text-align: center;
      font-family: Montserrat_semiBold, sans-serif;
      font-weight: 600;
      color: white;
      font-size: 40px;
      line-height: 45px;
    }

    .txt-header {
      color: white;
      font-size: 20px;
      text-align: center;
      width: 80%;
      margin: 20px auto 50px;
      font-family: 'Source_sans_regular';
    }

  }
}
.nc-CardCategory5{
.px-3{
  padding-left: 0;
}
.button-discover{
  margin: 20px 0;
}
}
.nc-SectionSliderNewCategories{
.nc-Section-Heading{
h2{
  font-family: Montserrat_semiBold;
}
}
.glide__track{
ul{
li{
h2{
  font-family: Montserrat_regular;
}
}
}
}
}
.container-accompagnement{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
.container-txt-accompagnement {
  width: 57%;

h2 {
  font-size: 40px;
  font-family: Montserrat_semiBold;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
}

p {
  color: black;
  font-family: Source_sans_regular;
}

.container-buttons {
button.btn-2 {
  background-color: #A08155;
  color: white;
  border: 1px solid #A08155;
}
button.btn-2:hover{
  background-color: #988363;
}
}
}
.container-image{
  position: relative;
  height: 422px;
img{
  width: 100%;
  margin-left: 50px;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.30) 0px 8px 16px -8px;
}
}
}


.expertise-single:first-child{
  margin-left: 100px;
}
.counter-expert{
  color: #A08155;
  font-size: 80px;
}

.container-partenaire{
  padding: 100px 15%;
  background-image: url("../images/bg-newsletter.jpg");
  background-repeat: no-repeat;
  background-size: cover;
h2{
  color: white;
  font-family: Montserrat_semiBold;
  font-weight: 600;
  font-size: 40px;
}
p{
  font-family: Source_sans_regular;
  width: 60%;
  color: white;
  padding: 30px 0;
}
}

.container-newsletter{
h2{
  font-size: 40px;
  line-height: 45px;
  font-weight: 600;
  text-align: center;
  font-family: Montserrat_semiBold;
  margin: 0 0 20px 0;
}
p{
  width: 30%;
  margin: 0 auto;
  color: black;
  text-align: center;
  font-size: 18px;
  font-family: 'Source_sans_regular';
}
form{
  padding: 30px 0;
  width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
input{
  border:  none;
  border-bottom: 1px solid black;
  border-radius: 0 !important;
  background-color: white !important;
  width: 100%;
  margin: 30px 0;
}
::placeholder{
  text-align: center;
}
}
}
.container-focus{
.text-base{
  font-family: Source_sans_regular;
  font-size: 18px;
}
}

.container-slider-fr{
  position: relative;
  .gradient-right{
    width: 100px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to right, transparent, #F8F8F8);
    z-index: 10;
  }
  div{
  div{
    img{
      width: 350px;
      height: 200px;
      object-fit: cover;
      border-radius: 20px;
    }
    h3{
      font-family: Montserrat_semibold;
      margin: 16px 0;
    }
    a{

    }
  }
  }
}

/* FOOTER */

.item-footer{
  font-size: 16px;
  font-family:Montserrat_semiBold;
  font-weight: 600;
  color: black !important;
  letter-spacing: 2px;
}
.nc-Footer{
  padding: 6rem;
  font-family: Montserrat_regular;
h2{
  margin-top: 30px;
  font-size: 25px;;
  font-family: Montserrat_semiBold;
}
}
.container-botfooter{
  background-color: #D6D6D6;
  width: 100%;
  padding: 0 30px;
  margin:0 auto;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
p{
  font-family: "Source_sans_regular";
  font-size: 16px;
  color: black;
a{
  text-decoration: underline;
}
}
}


/* PAGE PROPGRAMMES */

.margin-top{
  margin-top: 100px;
}

.container-programme{
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  background-color: #F5F5F5;
  border-radius: 20px;
.img-programme{
  width: 100%;
  height: 230px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
}
.container-content-programme{
  height: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items:  flex-start;
  justify-content: space-around;
h2{
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 30px;
  color: #3B0F70;
}
.txt-prix{
  color: #A08155;
  font-family: 'Source_sans_regular';
  font-size: 18px;
}

.container-type-nb{
  width: 100%;
.txt-type{
  font-weight: 700;
  font-family: 'Source_sans_bold';
}
.txt-nb{
  font-family: 'Source_sans_regular';
}
}

.txt-zone{
  font-family: 'Source_sans_regular';
  width: 100%;
}
a {
  background-color: transparent;
  color: #3B0F70;
  border: 1px solid #3B0F70;
  border-radius: 20px;
  padding: 0px 40px;
  font-family: Montserrat_regular;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}
a:hover{
  background-color: #3b0f70;
  color: white;
  transition: .2s;
}
}
}
.container-map-programmes{
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
  .list-btn{
    width: 60%;
    .container-list-gradient {
      position: relative;

      ul {
        height: 800px;
        overflow-x: hidden;
        overflow-y: scroll;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        padding: 0 30Px;
        position: relative;

        .container-programme {
          box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.30) 0px 8px 16px -8px;
        }
      }
      .gradient{
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 200px;
        background: linear-gradient(to bottom, transparent, white);
        z-index: 10;
      }
    }
}
  .container-only-map {
    width: 40%;
    height: 800px;

    div {
      border-radius: 20px;
    }
  }
}

.container-vosprojets{
  background-color: #3B0F70;
  height: 500px;
  margin: 0 0 0px 0;
.container-content-vosprojets {
  padding: 50px 0;
  width: 800px;
  text-align: center;
  margin: 0 auto;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
h2 {
  color: white;
  font-size: 40px;
  font-family: 'Montserrat';
  font-weight: 600;
}
p{
  color: white;
  font-family: Source_sans_regular;
  font-size: 18px;
  margin-top: 30px;
}
button{
  color:white;
  border: 1px solid white;
  margin-top: 30px;
  padding: 8px 32px;
  border-radius: 20px;
  font-family: 'Montserrat';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}
button:hover{
  background-color: white;
  color: #3b0f70;
  transition: .2s;
}
}
}
.popup-content{
  padding: 0px !important;
  border: none !important;
  border-radius: 10px;
}
.button-recherche-av{
  background-color:#A08155;
  color: white;
  padding: 8px;
  border-radius: 20px;
  font-family: 'Montserrat';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.container-content-recherche{
.title-recherche {
p {
  width: 100%;
  background-color: #A08155;
  color: white;
  padding: 16px 0;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 24px;
  font-family: 'Montserrat';
  font-weight: 600;
  letter-spacing: 2px;
}
}
.container-projet{
  padding: 30px 50px;
p{
  font-size: 20px;
  font-family: 'Montserrat';
  font-weight: 600;
  color: black;
}
.container-type-projet{
  margin: 30px 0;
label{
  margin: 0 20px 0 4px;
}
.input-loc{
  width: 80%;
  border: none;
  border-bottom: 1px solid grey;
}
}
}
}

.button-discover{
  border: 1px solid #3b0f70;
  padding: 2px 40px;
  border-radius: 20px;
  margin: 20px;
a{
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: Montserrat_semiBold;
  color: #3b0f70;
  font-size: 14px;
}
}
.button-discover:hover{
  background-color: #3b0f70;
  transition: .2s;
a{
  color: white;
}
}
.blog-home{
  .container-autres-articles{
    border-top: none;
    padding-bottom: 100px;
    .title-blog{
      font-family: Montserrat_semiBold;
      font-size: 40px;
      font-weight: 600;
      margin: 50px 0;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

/* SWITCH  VUE SANS MAP */
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked{
  color: red;
}
.programmes ul{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  .card-pg{
    .container-programme{
      box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px
    }
  }
}
/* PAGE GROUPE */
.container-groupeHeader{
  padding: 110px 6rem;
  background-image: url("../images/bg-newsletter.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 40px;
  max-width: inherit !important;
h1{
  color: white;
  font-family: Montserrat_semiBold;
  font-weight: 600;
  font-size: 40px;
}
p{
  font-family: 'Source_sans_regular';
  width: 700px;
  color: white;
  padding: 30px 0;
}
}
.naissance-kotimo{
  margin: 100px 0 100px 0;
  padding: 0 100px;
h2{
  line-height: 1.2;
  font-family: Montserrat_semiBold;
  font-size: 40px;
  font-weight: 600;
  margin: 30px 0;
}
p{
  font-family: Source_sans_regular;
  font-size: 18px;
  color: black;
}
}
.container-equipe {
  margin: 100px 0;
  text-align: center;
  padding: 10%;
  background-color: #F8F8F8;

.nc-Section-Heading {
.max-w-2xl {
  max-width: 100%;
  padding: 0 8em;
h2{
  font-family: Montserrat_semiBold;
}


.txt-equipe {
  color: black;
  font-family: Source_sans_regular;
}
}
}
.title-team{
  text-align: left;
  font-family: Montserrat_semiBold;
  color: #3b0f70;
}
.poste-team{
  text-align: left;
  font-family: Source_sans_bold;
  color: black;
}
.content-team{
  margin-top: 24px;
  text-align: left;
  font-family: Source_sans_regular;

}
}
.container-nos-valeur{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F8F8F8;
  padding: 10%;
.container-txt-nos-valeur{
  width: 50%;
h2{
  font-size: 40px;
  font-family: Montserrat_semiBold;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
}
p{
  margin: 20px 0 0 0;
  color: black;
  font-family: Source_sans_regular;
}
}
.container-image{
img{
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.30) 0px 8px 16px -8px;
}
}
}



/* PAGE DEVENIR PARTENAIRE */
.container-all-avantages{
.container-partenaire-page{
  padding: 100px 0;
  background-image: url("../images/bg-partenaire-page.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
h2{
  color: white;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 40px;
}
p{
  font-family: Source_sans_regular;
  width: 700px;
  color: white;
  padding: 30px 0;
  text-align: center;
}
button{
  background-color: white;
  padding: 8px 40px;
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 16px;
  border-radius: 20px;
}
}

.container-newsletter{
h2{
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  font-family: 'Montserrat';
  margin: 0 0 20px 0;
}
p{
  color: black;
  text-align: center;
  font-size: 20px;
  font-family: 'Source_sans_regular';
}
form{
  padding: 30px 0;
  width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
input{
  border-bottom: 1px solid black;
  width: 100%;
  margin: 30px 0;
}
::placeholder{
  text-align: center;
}
button{
  border: 1px solid #3B0F70;
  padding: 4px 24px;
  border-radius: 20px;
  margin: 30px auto;
  width: auto;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #3B0F70;
}
}
}
.container-avantage1{
  margin: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
h2{
  text-align: center;
  padding: 50px 0 30px 0;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 40px;
}
p{
  text-align: center;
  color: black;
  margin:0 0 30px 0;
  width: 450px;
  margin: 0 auto;
}
.container-avantages{
  display: flex;
  width: 80%;
  margin: 0 auto;
.container-avantage{
h3{
  font-size: 35px;
  color: #3B07;
  color: #3B0F70;
  font-family: 'Montserrat';
  font-weight: 600;
  padding: 0 0 20px 0;
}
width: 25%;
margin: 50px auto;
p{
  text-align: left;
  width: 100%;
}
}
}
button{
  background-color: #A08155;
  padding: 4px 24px;
  border-radius: 20px;
  font-family: 'Montserrat';
  font-weight: 600;
  color: white;
  letter-spacing: 2px;
}
}
}

.container-avantage2{
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 0;
.container-img-av2{
  width: 45%;
}
.container-txt-av2{
  color: black;
  width: 45%;
}
}

.container-avantage3 {
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
.container-title-txt{
  width: 45%;
h2{
  font-family: 'Montserrat';
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 20px 0;
}
button{
  background-color:#A08155;
  padding: 4px 32px;
  margin: 20px 0 0 0;
  color: white;
  border-radius: 20px;
}
}
.container-cards-part {
  display: flex;
  width: 45%;

.card-header {
  background-color: #3b0f70;
  border-radius: 10px;
  margin: 0 20px;
}

.ellipse-card {
  background-image: url("../images/Ellipse.png");
  background-repeat: no-repeat;
  background-position: 77px 12px;
  text-align: center;
  padding: 20px;
  color: white;
  font-family: 'Source_sans_regular';
  font-size: 20px;
}

.titre-card-header {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 2px;
  margin: 10px 0 20px 0;
  color: white;
}

.txt-card-header {
  text-align: center;
  padding: 0 16px;
  font-size: 16px;
  font-family: 'Source_sans_regular';
  margin: 0 0 20px 0;
  color: white;
}
}
}
.container-form-partenaire{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
h2{
  font-weight: 600;
  font-size: 40px;
  line-height: 45px;
  text-transform: uppercase;
  margin: 0 0 50px 0;
}
.container-form{
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
input{
  border: none;
  background-color: #F8F8F8;
  width: 600px;
  border-radius: 20px;
}
::placeholder{
  font-family: 'Source_sans_regular';
  text-align: center;
}
button{
  background-color: #A08155;
  margin: 30px 0 0 0;
  padding: 4px 32px;
  border-radius: 20px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}
}
}
}


/* PAGE BLOG */
.blogg{
  width: 90%;
}
.container-cards{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 50px;
  grid-column-gap: 50px;
  margin: 0 auto 70px;

a{
  justify-self: center;
.container-card{
  justify-self: center;
.container-img-component{
img{
  width: 100%;
  height: 300px;
  border-radius: 20px;
  object-fit: cover;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.30) 0px 8px 16px -8px;
}
}
h2{
  font-weight: 500;
  font-size: 25px;
  margin: 4px 0 4px 0;
  font-family: Montserrat_semiBold;
}
.category-blog{
  font-size: 14px;
  font-family:Montserrat_semiBold;
  text-transform: uppercase;
  font-weight: 700;
  margin: 20px 0 0 0;
  color: #A08155;
}
p{
  font-family: "Source_sans_regular";
  margin:  0 0 16px 0;
}
.duree-lecture{
  font-family: 'Source_sans_regular';
  color: #717171;
}
}
}
}
.button-container{
  width: 100%;
  margin: 0 0 100px 0 ;
  display: flex;
  justify-content: center;
}

.container-blogheader{
  margin: 100px 0;
h1{
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  margin:  0 0 20px 0;
  font-family: Montserrat_semiBold;
}
p{
  margin: 50px auto 0;
  text-align: center;
  font-family: Montserrat_regular;
}
}


/* SINGLE BLOG */

.title-single-blog{
  font-family: 'Montserrat_semibold';
}


/* COMPOSANT BLOG */


.container-all-blog{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
.title-blog{
  font-family: Montserrat_semiBold;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin: 50px 0;
  text-transform: uppercase;
}
.container-blogs{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 100px;
.container-blog{
  margin: 0 0 50px 0;
  width: 300px;
  justify-self: center;
.container-img-blog{
img{
  border-radius: 20px;
  width: 300px;
  height: 300px;
  object-fit: cover;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.30) 0px 8px 16px -8px;
}
}
h2{
  font-family: Montserrat_semiBold;
  font-weight: 500;
  font-size: 25px;
}
p{
  font-family: "Source_sans_regular";
  margin:  0 0 16px 0;
}
.category-blog{
  font-size: 14px;
  font-family: Montserrat_semiBold;
  text-transform: uppercase;
  font-weight: 700;
  margin: 20px 0 0 0;
  color: #A08155;
}
.duree-lecture{
  font-family: 'Source_sans_regular';
  color: #717171;
}
}
}
}

.container-single-blog{
.header-blog-single{
  margin: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
.fil-ariane{
  font-family: Source_sans_regular;
}
.postedAt{
  margin: 10px 0 0 0;
  font-family: Source_sans_regular;
}
.category-blog{
  font-size: 16px;
  font-weight: 700;
  color: #A08155;
  text-transform: uppercase;
}
.titleretour{
  display: flex;
  align-items: center;
img{
  width:25px;
  margin-right: 40px;
}
}
  img:hover{
    transform: translateX(-4px);
    transition: .3s;
  }
h1{
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}
p{
  font-family: Source_sans_regular;
  font-size: 16px;
  margin: 10px 0;
}
time{
  margin: 0;
  width: 100%;
  font-family: Source_sans_regular;
  font-size: 14px;
  color: #717171;
  text-align: center;
}
}
.container-bloc-1-single{
  margin: 100px 0;
.container-txt-1-single{
h2{
  font-size:40px;
  font-weight: 600;
  margin-bottom: 20px;
}
}
.container-image-1-single{
  width: 90%;
  height: 500px;
  margin: 50px auto;
img{
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.30) 0px 8px 16px -8px;
}
}
}
}
.nc-SocialsList{
  .container-icons-rs{
    width: 100%;
    display: flex;
    color: #3B0F70;
  }
  a{
    margin-right: 20px;
  }
}
.header-blog-single{
  .nc-SocialsList{
    a{
      margin: 0 10px;
    }
  }
}
.nc-SocialsList1{

.social-blog{
  margin-top: 0 !important;
}
}
.container-bloc-2-single{
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  margin: 100px 0;
.container-txt-2-single{
  width: 46%;
h2{
  font-size:40px;
  font-weight: 600;
  margin-bottom: 20px;
}
}
.container-image-2-single{
  width: 46%;
}
}
.container-citation{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
p:first-child{
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  font-style: italic;
  width: 80%;
  margin: 0 auto 20px;
}
p:last-child{
  font-family: Source_sans_regular;
  font-size: 20px;
}
}


.container-autres-articles {
  border-top: 1px solid black;

  .title-autres-articles {
    font-family: Montserrat_semiBold;
    font-size: 40px;
    font-weight: 600;
    width: 100%;
    text-align: left;
  }

  .container-aa-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 30px;
    grid-column-gap: 50px;
    margin: 0 0 70px 0;

    a {
      justify-self: center;

      .container-card {
        justify-self: center;

        .container-img-component {
          img {
            width: 100%;
            height: 300px;
            border-radius: 20px;
            object-fit: cover;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.30) 0px 8px 16px -8px;
          }
        }

        h2 {
          font-weight: 500;
          font-size: 25px;
          margin: 4px 0 4px 0;
          font-family: Montserrat_semiBold;
        }

        .category-blog {
          font-size: 14px;
          font-family: Montserrat_semiBold;
          text-transform: uppercase;
          font-weight: 700;
          margin: 20px 0 0 0;
          color: #A08155;
        }

        p {
          font-family: "Source_sans_regular";
          margin: 0 0 16px 0;
        }

        .duree-lecture {
          font-family: 'Source_sans_regular';
          color: #717171;
        }
      }
    }
  }
  .container-aa-cards-empty {
    grid-row-gap: 30px;
    grid-column-gap: 50px;
    margin: 0 0 30px 0;
    p{
      text-align: center;
    }
  }
}
.carousel-size{
  width: 400px;
}

/* PAGE FORMULAIRE PARTENAIRE */

.container-partenaire-page {
  padding: 100px 0;
  background-image: url("../images/bg-partenaire-page.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

h2 {
  color: white;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 40px;
}

p {
  font-family: Source_sans_regular;
  width: 700px;
  color: white;
  padding: 30px 0;
  text-align: center;
}
}

.container-form-partenaire{
  width: 100%;
h1{
  font-size: 40px;
  font-weight: 600;
  margin: 50px 0;
  font-family: Montserrat_semiBold;
}
}

.container-form-contact-component{
  width: 50%;
  margin: 0 auto;
form{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
.container-nom-prenom-part{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
input{
  color: white;
  width:100%;
  margin-bottom: 20px;
  background-color: #ADADAD;
  border: none;
  border-radius: 20px;
}
::placeholder{
  color: white !important;
}

}
input{
  color: white;
  width: 100%;
  margin-bottom: 20px;
  background-color: #ADADAD !important;
  border: none;
  border-radius: 20px !important;
}
  ::placeholder{
    color: white !important;
  font-family: Source_sans_regular;
}
textarea{
  color: white;
  width: 100%;
  background-color: #ADADAD;
  border: none;
  border-radius: 20px;
}
::placeholder{
  color: black;
}

}
  .separator{
    p{
      text-align: center;
      font-weight: bold;
      font-family: 'Montserrat_semiBold';
      font-size: 20px;
    }
  }
  .button-call{
    display: flex;
    justify-content: center;
    a{
      text-transform: uppercase;
    }
  }
}

.container-form-groupe{
  padding: 100px 0;
h2{
  font-family: Montserrat_semiBold;
  font-size: 40px;
  font-weight: 600;
  margin: 20px 0 50px 0;
  text-align: center;
}
}


/* PAGE COMING SOON */

.container-all-bg {
  width: 100%;
  height: 100vh;
  background-image: url("../images/bg-comingsoon.jpg");
  background-repeat: no-repeat;
  background-size: cover;
.container-comingsoon {
  background-image: url("../images/monogramme-kotimo.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 75px;
  border-radius: 20px;
  position: absolute;
  width: 700px;
  margin: auto;
  top: 25%;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
.container-logo {
  margin: 50px 0;

img {
  width: 300px;
  margin: 0 auto;
}

}

.container-coming {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
p {
  text-align: center;
  font-family: 'Source_sans_regular';
  font-size: 22px;
  color: white;
}
button {
  background-color: white;
  color: #3b0f70;
  padding: 4px 16px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 4px;
  border: 1px solid white;
}
button:hover{
  background-color: transparent;
  color: white;
  border: 1px solid white;
  transition: .5s;
}
}
}
}


/* PROGRAMME SINGLE */

.container-page-single-prog{
  margin: 50px 0;
.header-single-prog{
.txt-header-single-prog{
  margin: 0 0 20px 0;
h1{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 40px;
  margin: 32px 0 16px 0;
  text-transform: uppercase;
  line-height: 1.2;
}
p{
  font-family: 'Source_sans_regular';
  color: #717171;
span{
  text-transform: uppercase;
}
}
}
.container-images-single-prog {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 2px 2px 2Px 2px rgb(245, 245, 245);
  }
}
  .btns-contact{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.container-cara-ptsforts-single-prog{
  margin: 80px 0;
  display: flex;
  justify-content: space-between;
.container-cara{
  width: 47%;
h2{
  font-weight: 600;
  font-size: 40px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  margin: 0 0 20px 0;
}
div{
  height: 300px;
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 24px 0;
}
}
.container-ptsforts{
  width: 47%;
  background-color: #F8F8F8;
  padding: 24px;
  border-radius: 30px;
h2{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 40px;
}
ul{
  margin: 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 300px;
li{
  list-style-type: disc;
  padding-top: 24px;
}
}
}
}
.container-biens-dispo{
h2{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 40px;
  margin:  0 0 16px 0;
}
p{
  width: 60%;
}
button{
  background-color: #3b0f70;
  font-weight: 600;
  color: white;
  padding: 4px 24px;
  margin: 20px 0;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 14px;

}
}
.container-tableau{
.accordeon-single-prog{
#panel1bh-header{
  background-color: #F5F5F5;
  font-family: Source_sans_regular;
  padding: 0 50px;
}
.list-title:first-child{
  color:  #3b0f70;
  font-weight: 600;
}
}
}
.container-googlemaps{
  margin: 80px 0;
h2{
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  font-weight: 600;
  margin: 20px 0;
  text-transform: uppercase;
}
div{
div{
div{
  border-radius: 20px;
}
}
}
}
.container-interet{
  margin: 100px auto;
  width: 55%;
h2{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  margin: 20px 0;
}
p{
  font-family: Source_sans_regular;
  color: black;
  text-align: center;
  margin: 0 0 20px 0;
}
.container-form-contact-component{
  width: 100%;
}
}
}



/* PAGE ALL PROGRAMMES */

.container-programmes-all{
.fil-ariane{
  margin: 20px 0;
}
h1{
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  font-weight: 600;
  margin: 50px 0 20px;
}
p{
  width: 70%;
  font-family: Source_sans_regular;
}
}

/* PAGES CATEG BLOG */

.container-selector{
  width: 80%;
  margin: 50px auto;
  display: flex;
  justify-content: space-evenly;

.active{
  border-bottom: 1px solid black;
}
a{font-family: Montserrat_semiBold;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 4px;
  font-size: 14px;
}
}


/* DARKMODE */
.dark {


  /* BOUTONS */

  .button-v{
    font-family: 'Montserrat_semiBold';
    color: white;
    background-color: #A08155;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
    letter-spacing: 2px;
  }
  .button-v:hover{
    background-color: #D5C5AF;
    transition: .5s;
  }
  .button-g{
    background-color: #A08155;
    padding: 8px 16px;
    border-radius: 20px;
    color: white;
    font-size: 14px;
    font-family: 'Montserrat_semiBold';
    LETTER-SPACING: 2px;
  }
  .button-g-out{
    background-color: transparent;
    border: 1px solid white;
    padding: 8px 16px;
    border-radius: 20px;
    color: white;
    font-size: 14px;
    font-family: 'Montserrat_semiBold';
    LETTER-SPACING: 2px;

  }
  .button-g-out:hover{
    background-color: white;
    color: #A08155;
    transition: .5s;
  }
  .button-g:hover{
    transition: .5s;
    background-color: #D5C5AF;
  }
  .button-v-out{
    border: 1px solid #A08155;
    padding: 8px 16px;
    border-radius: 20px;
    font-family: Montserrat_semiBold;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #A08155;
    font-size: 14px;
  }
  .button-v-out:hover {
    background-color: #D5C5AF;
    color: white;
    transition: .5s;
  }

  /* INPUTS */
  textarea{
    background-color: white !important;
  }
  .inputs-np{
    input{
      background-color: white !important;
    }
  }
  input[type="email"]{
    background-color: white !important;
  }


    .nc-MainNav1.notOnTop{
    border-bottom: none;
    background-color: #374151 !important;
  }
.container-programme .container-content-programme h2{
  color: white;
}

.container-programme .container-content-programme a{
  background-color: white;
  color: #3B0F70;
  border: none;
  font-family: Montserrat_regular;
}
.container-programme .container-content-programme a:hover{
  background-color: #A08155;
  color: white;
  transition: linear .2s;
}
.container-programmes-all .fil-ariane{
  color: white;
}
.la-facebook-square:before{
  color: #FFFFFF;
}

.la-instagram:before{
  color: #FFFFFF;
}

.la-linkedin:before{
  color: #FFFFFF;
}



circle{
  fill: white;
}
.nc-MainNav1 {
  background-color: #121826;
.nc-Button{
  background-color: white;
  color: #3B0F70;
}
.nc-Button:hover{
  background-color: #A08155;
  transition: linear .2s;
  color: white;
}
}
.container-buttons {
button.btn-2{
  background-color: white;
  color: #3B0F70;
}
button.btn-2:hover{
  background-color: #A08155;
  transition: linear .2s;
  color: white;
}
}

.container-content-headerhome {
.container-cards-header {
.card-header {
.titre-card-header {
  color: black;
}

.txt-card-header {
  color: black
}
}
}

.container-formheader {
form {
select {
  color: black;
}
}
}
}

.container-focus {
  background-color: #121826;
  color: white;
button {
  background-color: white;
  color: #3B0F70;

i {
  color: black;
}
}
button:hover{
  background-color: #A08155;
  transition: linear .2s;
  color: white;
}
}

.container-accompagnement {

p {
  color: white;
}
}


.container-newsletter {
form {
input {
  background-color: transparent !important;
  border-bottom: 1px solid white;
}

::placeholder {
  color: white;
}
}

p {
  color: white;
}
}

.nc-Footer {
  background-color: #121826;
}

.item-footer {
  color: white !important;
}

.item-footer[href="tel:+33716263818"] {
  background-color: white;
  color: black !important;
  padding: 8px 32px;
  border-radius: 20px;
  border: 1px solid white;
}

.item-footer[href="tel:+33716263818"]:hover {
  background-color: transparent;
  color: white !important;
  border: 1px solid white;
  padding: 8px 32px;
  border-radius: 20px;
}

.item-footer[href="mailto:hello@kotimo.fr"] {
  background-color: transparent;
  border: 1px solid white;
  color: white !important;
  padding: 8px 32px;
  border-radius: 20px;
}
.item-footer[href="mailto:hello@kotimo.fr"]:hover {
  background-color: white;
  border: 1px solid white;
  color: black !important;
  padding: 8px 32px;
  border-radius: 20px;
}
.naissance-kotimo{
p{
  color: white;
}
}
.nc-SectionFounder{
  background-color: #121826;
}
.container-equipe .nc-Section-Heading .max-w-2xl .txt-equipe{
  color: white;
}
.container-nos-valeur{
  background-color: #121826;
p{
  color: white;
}
}
.container-selector{
.active{
  border-bottom: 1px solid white;
}
}
.container-cards a .container-card a{
  border: 1px solid white;
  color: white;
}
.container-cards a .container-card a:hover{
  background-color: white;
  color: black;
}
.container-partenaire button{
  color: black;
}
.container-form-contact-component {
form{
.container-nom-prenom-part {
input{
  color: black;
}
}
input{
  color:black;
}
  ::placeholder{
    color:black !important;
  }
textarea{
  color: black;
}
}
}
}


.loading-page{
  width: 100vw;
  height: 100vh;
  text-align: center;
  margin: auto;
h1{
  font-family: Montserrat_bold;
  font-size: 56px;
  transform: translateY(1500%);
}
.rings{
  transform: translate(45%, 400%);
}
}

circle{
  fill: #3b0f70;
}

.container-calcu{
  background-color: #F5F5F5;
  padding: 100px 0;
  h2{
  font-family: Montserrat_SemiBold;
}
p{
  width: 50%;
  font-family: Source_sans_regular;
  margin: 20px auto 30px;
}
.wrapperInner{
.container-response{
p {
  color: white;
}

}
}
}

.disable{
  display: none;
}
.inputs-calcu{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 200px;
  align-items: flex-end;
  width: 75%;
  margin: 0 auto;
}
.container-champs-calcu{
  display: flex;
  border: 1px solid #3B0F70;
  border-radius: 20px;
  width: 65%;
  margin: auto;
form{
  padding: 20px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 20px;
}
.container-response{
  background-color: #3B0F70;
  width: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
p{
  text-align: center;
  height: 90px;
}
.text-result-calc{
  font-size: 44px;
}
}
}
.resultTest{
  font-family: Source_sans_regular;
  line-height: 1.2;
}
.resultBlock{
  width: auto;
  display: inline-block;
}
.container-calcul{
  font-family: Source_sans_regular;
.wrapperInner{
  color: #3b0f70;
}
.input-control{
  width: 100%;
.input-response-solo{
  display: flex;
  justify-content: space-between;
}
output{
  margin-left: 32px;
  font-weight: 700;
}
input[type=range] {
  height: 26px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background: transparent;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 15px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #C4C4C4;
  border-radius: 20px;
  border: 0px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #00001E;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: #3B0F70;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -2.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #C4C4C4;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 15px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #C4C4C4;
  border-radius: 20px;
  border: 0px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #00001E;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: #3B0F70;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 15px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #C4C4C4;
  border: 0px solid #010101;
  border-radius: 40px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #C4C4C4;
  border: 0px solid #010101;
  border-radius: 40px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #00001E;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: #3B0F70;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #C4C4C4;
}
input[type=range]:focus::-ms-fill-upper {
  background: #C4C4C4;
}
}
}


/* Page CONNEXION */

.container-all-inscrip{
  width: 60%;
  margin: 100px auto;
  display: flex;
img{
  width: 30%;
}
.container-form{
  width: 70%;
  background-color:#3b0f70;
  padding:20px 50px;
h1{
  margin: 20px;
  text-transform: uppercase;
  font-family: Montserrat_SemiBold;
  color: white;
  font-size: 40px;
}
.el-card.is-always-shadow{
  border: none;
  background-color: transparent;
  box-shadow: inherit;
.el-card__body{
h2{
  display: none;
}
button{
  background-color: #A08155;
  border: 1px solid #A08155;
  padding:4px 32px;
  width: inherit;
  font-family: Montserrat_semiBold;
  text-transform: uppercase;
  font-size: 14px !important;
  letter-spacing: 2px;
  border-radius: 20px;
}
}
}

}
}



/* Page CONNEXION */

.container-all-connexion{
  width: 60%;
  margin: 100px auto;
  display: flex;
img{
  width: 30%;
}
.container-form{
  width: 70%;
  background-color:#F5F5F5;
  padding:20px 50px;
h1{
  margin: 20px;
  text-transform: uppercase;
  font-family: Montserrat_SemiBold;
  color: black;
  font-size: 40px;
}
.el-card.is-always-shadow{
  max-width: 100%;
  border: none;
  background-color: transparent;
  box-shadow: inherit !important;
.el-card__body{
h2{
  display: none;
}
button{
  background-color: #A08155;
  border: 1px solid #A08155;
  padding:4px 32px;
  width: inherit;
  font-family: Montserrat_semiBold;
  text-transform: uppercase;
  font-size: 14px !important;
  letter-spacing: 2px;
  border-radius: 20px;
}
}
}

}
}

.title-dashboard{
  font-family: Montserrat_semiBold;
  color: #A08155;
  font-size: 40px;
  padding:0 50px;
  margin-bottom: 30px;
}
.Account-header-bread{
  padding: 30px;
}
.mes-infos{
  -webkit-box-shadow: 3px 0px 18px 5px rgba(0,0,0,0.05);
  box-shadow: 3px 0px 18px 5px rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 30px;
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
h2{
  width: 25%;
  font-family: Montserrat_semiBold;
  font-size: 26px;
  color: #A08155;
  text-transform: uppercase;
}
.Account-infos{
  width: 65%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 30px;
.infos-detail{
  width: 300px;
  font-family: Source_sans_regular;
}
}
button a{
  background-color: #A08155;
  color: white;
  padding: 4px 32px;
  border-radius: 20px;
  letter-spacing: 4px;
  font-family: Montserrat_semiBold;
  text-transform: uppercase;
}
}

.container-bloc-rech-fav{
  width: 85%;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
div{
h2{
  color: #A08155;
  font-family: Montserrat_semiBold;
  font-size: 22px;
  text-transform: uppercase;

}
}
.bloc-favori{
  border-radius: 10px;
  width: 30%;
  -webkit-box-shadow: 3px 0px 18px 5px rgba(0,0,0,0.05);
  box-shadow: 3px 0px 18px 5px rgba(0,0,0,0.05);
  padding: 20px;
h2:before{
  content: url("../images/heart.png");
  margin-right: 8px;
}
}
.bloc-projet{
  width: 30%;
  background: #3b0f70;
  border-radius: 10px;
  -webkit-box-shadow: 3px 0px 18px 5px rgba(0,0,0,0.05);
  box-shadow: 3px 0px 18px 5px rgba(0,0,0,0.05);
  padding: 20px;
.container-infos-projet{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 350px;
div{
  margin: 24Px 0;
p{
  font-family: Source_sans_regular;
  color: white;
  font-weight: 700;
}
}
}
h2{
  color: #ffffff;
  margin-bottom: 30px;
}
button a {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 8px 32px;
  font-family: Montserrat_semiBold;
  text-transform: uppercase;
  color: #3b0f70;
  letter-spacing: 4px;
}
}
.bloc-recherche{
  border-radius: 10px;
  width: 30%;
  -webkit-box-shadow: 3px 0px 18px 5px rgba(0,0,0,0.05);
  box-shadow: 3px 0px 18px 5px rgba(0,0,0,0.05);
  padding: 20px;
}
}


/* PAGE FORM MODIFS INFOS */

.container-form-modifs-compte{
h1{
  font-family: Montserrat_semiBold;
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 50px;
}
form{
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 50%;
  margin: 50px auto;
.container-2champs{
  display: flex;
  justify-content: space-evenly;
}
input{
  background-color: #F5F5F5;
  border-radius: 20px;
  border: none;
  margin: 16px auto;
  width:45%;
}
::placeholder{
  color: white;
}
button{
  border: 1px solid #A08155;
  background-color: #A08155;
  border-radius: 20px;
  color: white;
  margin: 32px auto;
  padding: 4px 32px;
  font-family: Montserrat_semiBold;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.message{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
}
}

.onTop{
.bigLogo{
  width: 200px;
  transition: .2s linear;
}
.navigation-little{
  padding: 1.25rem 0 1.25rem 0;
  transition: .2s linear;
}
}
.notOnTop{
.bigLogo{
  width: 130px;
  transition: .2s linear;
}
.navigation-little{
padding: 0;
  transition: .2s linear;
}
}

.text-sm {
ul{
li{
  height: 40px;
}
}
}

.la-facebook-square:before{
  color: #3B0F70;
}

.la-instagram:before{
  color: #3B0F70;
}

.la-linkedin:before{
  color: #3B0F70;
}
.container-button-projet{
.nc-Button{
  display: table;
  margin: auto;
  background-color:#A08155;
}
}



/* PAGE MENTIONS LEGALES */
.mentions-leg{
h2{
  font-weight: 600;
  font-size: 40px;
  line-height: 45px;
  text-transform: uppercase;
  margin: 40px 0 16px 0;
}
a{
  text-decoration: underline;
}
}

.container-paragraphe-1{
  font-family: 'Source_sans_regular';
    h1{
  font-weight: bold;
  font-size:28px;
  margin: 0 0 20px 0;
}
h2{
  font-family: Source_sans_bold;
  font-weight: 600;
  margin: 0 0 20px 0;
  font-size: 24px;
}
p{
  margin: 0 0 20px 0;
}
ul{
  list-style: inside;
  margin: 0 0 20px 0 ;
}
}

.resized{
  height: 85px;
}
.title-focus-reg{
  margin-bottom: 24px;
}


/* COMPOSANT CONTACT */
.inputs-np{
  width: 49%;
  display: flex;
  flex-direction: column;
}
.messageError{
  color: red;
  font-family: Source_sans_bold;
  margin: 0 0 20px 8px;
  text-align: center;
}
.successMessage{
  color: green !important;
  font-family: Source_sans_bold !important;
}












.wrapper {
  width: 96%;
  max-width: 1140px;
  margin: 0 auto;
}
.card-grid {
  margin: 2em 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 48px;
}
.card {
  background-color: var(--bg-offset);
  padding: 0px;
  box-shadow: 0px 2px 4px var(--border);
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: scale(1.1);

    .card-content {
      h2 {
        display: block;
        -webkit-line-clamp: none;
        -webkit-box-orient: none;
        overflow: visible;
      }
    }
  }
}
.card-image {
  max-height: 150px;
  overflow: hidden;
}
.card-image img {
  margin-top: -13px;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.card-content {
  padding: 32px 15px;

  h2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.card-list {
  margin-top: 16px;

  li {
    color: var(--text);
    margin-top: 8px;

    span {
      color: var(--gray);
    }
  }
}

/* search input */
.search-wrapper {
  margin: 48px 0;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 375px) {
  .search-input {
    width: 100%;
  }
  .search-wrapper {
    justify-content: start;
    flex-wrap: wrap;
  }
  .select {
    margin-top: 3.5em;
  }
}

.search-input {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xNS44NTMgMTYuNTZjLTEuNjgzIDEuNTE3LTMuOTExIDIuNDQtNi4zNTMgMi40NC01LjI0MyAwLTkuNS00LjI1Ny05LjUtOS41czQuMjU3LTkuNSA5LjUtOS41IDkuNSA0LjI1NyA5LjUgOS41YzAgMi40NDItLjkyMyA0LjY3LTIuNDQgNi4zNTNsNy40NCA3LjQ0LS43MDcuNzA3LTcuNDQtNy40NHptLTYuMzUzLTE1LjU2YzQuNjkxIDAgOC41IDMuODA5IDguNSA4LjVzLTMuODA5IDguNS04LjUgOC41LTguNS0zLjgwOS04LjUtOC41IDMuODA5LTguNSA4LjUtOC41eiIvPjwvc3ZnPg==");
  background-color: var(--bg-offset);
  background-size: 16px 16px;
  background-position: left 10px center;
  background-repeat: no-repeat;
  padding: 1.4em 2em;
  padding-left: 2.7em;
  border: 1px solid var(--border);
  color: var(--gray);
  box-shadow: 0px 4px 6px var(--border);
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

  &:hover {
    box-shadow: 0px 0px 0px var(--border);
  }
}
/* select from moderncss.dev */
select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none !important;
  // Additional resets for further consistency
  outline: none;
  background-color: var(--bg-offset);
  border-radius: 0.25em;
  border-width: 1px;
  border-style: solid;
  border-color: var(--border);
  padding: 1.4em 2em 1.4em 1em;
  margin: 0;
  margin-right: 1em;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  width: 100%;
  color: var(--gray);
}
.select {
  min-width: 15ch;
  max-width: 30ch;
  cursor: pointer;
  line-height: 1.1;
  background-color: transparent;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  box-shadow: 0px 4px 6px var(--border);
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

  &:hover {
    box-shadow: 0px 0px 0px var(--border);
  }

  &::after {
    content: "";
    display: block;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--text);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
    margin-right: 1em;
  }
}
select,
.select:after {
  grid-area: select;
}
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--text);
  border-radius: inherit;
}
.container-page-single-prog{
  margin: 50px auto;
}


/* BLOC RECHERCHER PAR VILLE */

.title h2{
  font-family: "Montserrat_semibold";
  font-size: 40px;
  color: black;
  text-transform: uppercase;
}
.list-ville {
  display: flex;
  flex-direction: inherit;
  flex-wrap: wrap;
  height: auto;
  margin: 24px 0;

  p {
    margin: 16px 0;
    width: 25%;
  }
}
.loader-ville{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
p{
  text-align: center;
  font-family: "Montserrat_semibold";
  font-size: 30px;
}
}
.search-input{
  width: 500px;
  background-color: #A08155;
  /* color: white; */
  border-radius: 40px;
  padding: 8px 39px;
}
.search-input::placeholder{
  color: white;
  font-family: Montserrat_regular, sans-serif;
}
.search-input:focus{
  color: white;
}
.container-filter-ville{
  margin: 10% 0 0;
}

.container-tableau{
  .MuiPaper-root{
    border-radius: 30px;
    .header-tableau{
      background-color: #3b0f70;
      tr{
        th{
          color: white;
        }
      }
    }
  }
}

.icone-bien{
  width: 40px;
}



/** BANNIERE COOKIES **/
button[data-cookiefirst-action="accept"][data-cookiefirst-button="primary"]{
  background-color: #3b0f70 !important;
  border-color: #3b0f70 !important;
}
button[data-cookiefirst-action="reject"][data-cookiefirst-button="secondary"]{
  background-color: #3b0f70 !important;
  border-color: #3b0f70 !important;
}
button[data-cookiefirst-action="adjust"][data-cookiefirst-button="secondary"]{
  background-color: #3b0f70 !important;
  border-color: #3b0f70 !important;
}button[data-cookiefirst-action="accept"][data-cookiefirst-button="primary"]:hover{
   color: #3b0f70 !important;
   background: inherit !important;
   border-color: #3b0f70 !important;
}
button[data-cookiefirst-action="reject"][data-cookiefirst-button="secondary"]:hover{
  color: #3b0f70 !important;
  background: inherit !important;
  border-color: #3b0f70 !important;
}
button[data-cookiefirst-action="adjust"][data-cookiefirst-button="secondary"]:hover{
  color: #3b0f70 !important;
  background: inherit !important;
  border-color: #3b0f70 !important;
}
a[data-cookiefirst-outline-accent-color="true"]{
  color: #3b0f70 !important;
}
.cookiefirst-root button[data-cookiefirst-button="primary"][type="button"]{
  background: #A08155 !important;
  border-color: #A08155 !important;
}
.cookiefirst-root button[data-cookiefirst-button="primary"][type="button"]:hover{
 color: #3b0f70 !important;
}
.cookiefirst-root [data-cookiefirst-accent-color="false"][type="button"]{
  color: #3b0f70 !important;
}
.cookiefirst-root [data-cookiefirst-accent-color="true"][type="button"][aria-selected="true"]{
  color: #A08155 !important;
}


/** COMPOSANT NOS PROGRAMMES - HOME **/

.programmesh{
  &-display{
    display: grid;
    grid-template-columns: 0px repeat(3, 1fr) 0px;
    grid-column-gap: 50px;


    .programmesh-container{
      background-color: #F8F8F8;
      border-radius: 20px;
      font-size: 16px;
      font-family: "Source_sans_regular", sans-serif;
      img{
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        height: 220px;
        object-fit: cover;
      }
      &-t{
        &-title{
          font-size: 28px !important;
          font-family: Montserrat, sans-serif;
        }
        &-genre{
          font-family: "Source_sans_bold", sans-serif !important;

        }
      }


    }


  }


}